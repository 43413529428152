<template>
  <v-container>
    <h1 class="display-1 ma-6">Отдел закупок Полисервис</h1>
    <v-row dense>
      <v-col
        v-for="(item, index) in items"
        :key="index"
        xs="12"
        sm="6"
        md="4"
      >
        <v-card
          :to="`${$route.path}/${item.url}`"
          hover
          height="100%"
        >
          <div class="d-flex flex-column justify-space-between fill-height">
            <v-card-title>
              {{item.title}}
            </v-card-title>
            <div class="py-2 icon--opacity">
              <v-img
                :src="item.img"
                height="100px"
                contain
              >
              </v-img>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PurchasesDepFormsList',
    data: () => ({
      items: [
        {
          title: 'Закупка',
          url: 'purchase',
          img: './img/shop.svg'
        },
        {
          title: 'Запрос цен',
          url: 'request-for-prices',
          img: './img/tag.svg'
        },
        {
          title: 'Регистрация под проект',
          url: 'registration',
          img: './img/briefing.svg'
        }
      ]
    })
  }
</script>
